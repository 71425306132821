import React, { useEffect, useState } from "react";
import { Card, List, Button } from "antd";
import { Modal } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import "moment/locale/pt-br";
const { Meta } = Card;

export default function ReceptorModal({ payload }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [messages, setMessages] = useState([]);

  

  const renderListItem = (item) => (
    
    <List.Item>
      <List.Item.Meta
        title={item.topic}
        description={
          item.message + " " + moment(new Date().toString()).format("llll")
        }
      />
   
    </List.Item>
    
  );

  const record = {
    topic: "testtopic/agteletronic",
    qos: 0,
    payload: "Websocket Conectado",
  };

  useEffect(() => {
    if (payload.topic) {
      setMessages((messages) => [...messages, payload]);
      console.log();
    }
  }, [payload]);

  const limpar = () => {
    setMessages("");
  };

  return (
    <>
       <Card
        hoverable
        style={{
          width: 170,
          marginleft: 'auto',
  marginright: 'auto',
        }}
        cover={
          <img
            alt="example"
            src="https://suporte.agteletronic.com.br/icons/Atis(7).png"
            onClick={handleShow}
            style={{
              width: 145,
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "5px",
              margin: "10px",
              backgroundColor: "#f5faf6",
            }}
          />
        }
      >
        <Meta
          title="Histórico"
          onClick={handleShow}
        />
      </Card>
      <br/>
      <Modal className="special_modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Histórico
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Card>
      <Paper style={{ maxHeight: 300, overflow: "auto" }}>
        <List
          size="small"
          bordered
          dataSource={messages}
          renderItem={renderListItem}
        />
      </Paper>
      <Button success onClick={limpar} style={{ marginTop: "10px" }}>
        Limpar
      </Button>
    </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
