import React from "react";
import HookMqtt from "./components/firemqtt/";
import Nave from "./Nav";
import Footer from "./Footer";
import "./App.css";

function App() {
  return (
    <div>
      <Nave />
      <div className="container mt-3">
        <div className="row">
          <div className="col-xl-12">
            <HookMqtt />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
