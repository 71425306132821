import React, { useState } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";



export default function Nave() {
 
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/" ><h5 className="headin5_amrc col_white_amrc" ><i className="fa fa-cogs" ></i> Cliente MQTT</h5></Navbar.Brand>

      </Container>
    </Navbar>
  );
}