import React, { useState, useContext } from "react";
import { useSpeechRecognition } from "react-speech-kit";
import { Button, Card } from "antd";
import { Modal } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { Container } from "./shared";
const { Meta } = Card;

export default function SpeechModal({ publish }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState("");
  const [lang, setLang] = useState("pt-BR");
  const [qos, setQos] = useState("");
  const [topic, setTopic] = useState("clientemqtt");
  const [blocked, setBlocked] = useState(false);

  const onEnd = () => {
    onFinish();
  };

  const languageOptions = [
    { label: "Cambodian", value: "km-KH" },
    { label: "Deutsch", value: "de-DE" },
    { label: "English", value: "en-AU" },
    { label: "Farsi", value: "fa-IR" },
    { label: "Français", value: "fr-FR" },
    { label: "Italiano", value: "it-IT" },
    { label: "普通话 (中国大陆) - Mandarin", value: "zh" },
    { label: "Português", value: "pt-BR" },
    { label: "Español", value: "es-MX" },
    { label: "Svenska - Swedish", value: "sv-SE" },
  ];

  const QosOptions = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
  ];



  const onResult = (result) => {
    setValue(result);
  };

  const changeLang = (event) => {
    setLang(event.target.value);
  };

  const changeQos = (event) => {
    setQos(event.target.value);
  };

  const changeTopic = (event) => {
    setTopic(event.target.value);
  };

  const onError = (event) => {
    if (event.error === "not-allowed") {
      setBlocked(true);
    }
  };

  const { listen, listening, stop, supported } = useSpeechRecognition({
    onResult,
    onEnd,
    onError,
  });

  const toggle = listening
    ? stop
    : () => {
        setBlocked(false);
        listen({ lang });
      };

  const record = {
    topic: "clientemqtt",
    qos: 0,
  };

  const onFinish = () => {
    publish(topic, qos, value);
  };

  return (
    <>
       <Card
        hoverable
        style={{
          width: 170,
          marginleft: 'auto',
  marginright: 'auto',
        }}
        cover={
          <img
            alt="example"
            src="/icons/6217281_call_chat_people_smartphone_video_icon.png"
            onClick={handleShow}
            style={{
              width: 145,
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "5px",
              margin: "10px",
              backgroundColor: "#f5faf6",
            }}
          />
        }
      >
        <Meta
          title="Voz"
          onClick={handleShow}
        />
      </Card>
      <br/>
      <Modal
        className="special_modal"
        show={show}
        onHide={handleClose}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Comandos por fala
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <form id="speech-recognition-form"
            >
              {!supported && (
                <p>
                  Ah, não, parece que seu navegador não suporta 
                  Reconhecimento por fala
                </p>
              )}
              {supported && (
                <React.Fragment>
                  <p>
                    {`Clique em 'Ouvir' e comece a falar.
               O reconhecimento por fala fornecerá uma transcrição do que você está dizendo.`}
                  </p>
                  <label htmlFor="language">Língua</label>
                  <select
                    form="speech-recognition-form"
                    id="language"
                    value={lang}
                    onChange={changeLang}
                  >
                    {languageOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="transcript">Transcrição</label>
                  <textarea
                    id="transcript"
                    name="transcript"
                    placeholder="Esperando para anotar..."
                    value={value}
                    rows={3}
                    disabled
                  />
                  <label htmlFor="topic">Tópico</label>
                  <textarea
                    id="topic"
                    name="topic"
                    placeholder="tópico"
                    value={topic}
                    rows={1}
                    onChange={changeTopic}
                  />
                  <label htmlFor="qos">Qos</label>
                  <select
                    form="speech-recognition-form"
                    id="qos"
                    value={qos}
                    onChange={changeQos}
                  >
                    {QosOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <button disabled={blocked} type="button" onClick={toggle}>
                    {listening ? "Parar" : "Ouvir"}
                  </button>
                  {blocked && (
                    <p style={{ color: "red" }}>
                      O microfone está bloqueado para este site no seu
                      navegador.
                    </p>
                  )}
                </React.Fragment>
              )}
            </form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
