import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="container bottom_border">
          <div className="row">
            <div className=" col-sm-4 col-md col-sm-4  col-12 col">
              <h5 className="headin5_amrc col_white_amrc pt2">Encontre-nos</h5>
              {/*headin5_amrc*/}
              <p>
                <i className="fa fa-location-arrow" /> Kanagawa ken Yokohama shi
                - Japão{" "}
              </p>
              <p>
                <i className="fa fa-phone" /> +81-9017083222
              </p>
              <p>
                <i className="fa fa fa-envelope" /> contato@agteletronic.com.br
              </p>
            </div>
            <div className=" col-sm-4 col-md  col-6 col">
              <h5 className="headin5_amrc col_white_amrc pt2">Links Rápidos</h5>
              {/*headin5_amrc*/}
              <ul className="footer_ul_amrc">
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/developer?id=AKIO+GADELHA+TOKUYOSI"
                  >
                    Aplicativos no Google Play
                  </a>
                </li>
              </ul>
              {/*footer_ul_amrc ends here*/}
            </div>
            <div className=" col-sm-4 col-md  col-6 col">
              <h5 className="headin5_amrc col_white_amrc pt2">Links Rápidos</h5>
              {/*headin5_amrc*/}
              <ul className="footer_ul_amrc">
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://clientemqtt.com.br"
                  >
                    Cliente Mqtt
                  </a>
                </li>
              </ul>
              {/*footer_ul_amrc ends here*/}
            </div>
            <div className=" col-sm-4 col-md  col-12 col">
              <h5 className="headin5_amrc col_white_amrc pt2">Política</h5>
              {/*headin5_amrc ends here*/}
              <ul className="footer_ul2_amrc">
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://agteletronic.com.br/privacy-policy.html"
                  >
                    Política de Privacidade
                  </a>
                </li>
              </ul>
              {/*footer_ul2_amrc ends here*/}
            </div>
          </div>
        </div>
        <div className="container">
          
          {/*foote_bottom_ul_amrc ends here*/}
          <p className="text-center">
            Todos os direitos reservados © 2020 - {new Date().getFullYear()} |
            Desenvolvido por{" "}
            <a href="https://agteletronic.com.br/">AGTELETRONIC</a>
          </p>
          <ul className="social_footer_ul">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/"
              >
                <i className="fab fa-facebook-f" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.twitter.com/"
              >
                <i className="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/"
              >
                <i className="fab fa-linkedin" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/"
              >
                <i className="fab fa-instagram" />
              </a>
            </li>
          </ul>
          {/*social_footer_ul ends here*/}
        </div>
      </footer>
    </div>
  );
}
