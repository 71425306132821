import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import { Card, List, Button, Col, Row } from "antd";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import "moment/locale/pt-br";
import { Palette } from "@material-ui/icons";

function getRandomNumber() {
  return Math.random() * 30;
}


const ColumnChart = ({ payload }) => {
  const [messages, setMessages] = useState([]);
  const [carregar, setCarregar] = useState([["Element", "Temperatura", { role: "style" }],
  ["", 0, "#2E2EFF"], // RGB value
  ])
  

  useEffect(() => {
    if (payload.topic) {
      setMessages((messages) => [...messages, payload]);
      if (payload.topic == "testtopic/agteletronic/temperatura") {
        var now = new moment();
        var con = [now.format("HH:mm:ss"), payload.message, "#2E2EFF"]
        carregar.push(con);
      }
    }
  }, [payload]);

  
  return (
    <Card title="Medidores">
       <Row>
      <Col xs={12} lg={12}>
      <Chart chartType="ColumnChart" width="100%" height="200px" data={carregar} />
      </Col>
      <Col xs={12} lg={12}>
      <Chart chartType="ColumnChart" width="100%" height="200px" data={carregar} />
      </Col>
      </Row>
    </Card>
  );
};
export default ColumnChart;