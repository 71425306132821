import React, { createContext, useEffect, useState } from "react";
import { Card, Row } from "antd";
import Connection from "./connection";
import mqtt from "mqtt";
import PublishModal from "./publisher/modal";
import SubscriberModal from "./subscriber/modal";
import RegistroAddhorasModal from "./addhoras/modal";
import RegistroAddnomesModal from "./addnomes/modal";
import RegistroAddregrasModal from "./addregras/modal";
import ReceptorModal from "./receptor/modal";
import ModalLanFatura from "./LancarFatura/Modal";
import SpeechModal from "./speechkit/speechmodal";
import SynthesisModal from "./speechkit/synthesismodal";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Chatbot } from "./chatbot/index";
import Gauge from './gauge/gaugechart'
import ColumnChart from "../columnchart/columnchart";
export const QosOption = createContext([]);
const qosOption = [
  {
    label: "0",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
];

const HookMqtt = () => {
  const [client, setClient] = useState(null);
  const [isSubed, setIsSub] = useState(false);
  const [payload, setPayload] = useState({});
  const [connectStatus, setConnectStatus] = useState("Conectar");

  const mqttConnect = (host, mqttOption) => {
    setConnectStatus("Conectando");
    setClient(mqtt.connect(host, mqttOption));
  };

  const { enqueueSnackbar } = useSnackbar();

  const outro = (topico, mensagem) => {
    handleClick("Recebido no tópico: " + topico + " Msg: " + mensagem, "info");
  };

  const handleClick = (dados, variant) => {
    enqueueSnackbar(dados, { variant });
  };

  useEffect(() => {
    if (client) {
      client.on("connect", () => {
        setConnectStatus("Conectado");
        handleClick("Conectado com broker!", "success");
      });
      client.on("error", (err) => {
        console.error("Erro de conexão: ", err);
        handleClick("Conectado com broker!", "success");
        client.end();
      });
      client.on("reconnect", () => {
        setConnectStatus("Reconectando");
      });
      client.on("message", (topic, message) => {
        const payloads = { topic, message: message.toString() };
        setPayload(payloads);
        outro(topic.toString(), message.toString());
      });
    }
  }, [client]);

  const mqttDisconnect = () => {
    if (client) {
      client.end(() => {
        setConnectStatus("Conectar");
      });
    }
  };

  const mqttPublish = (context) => {
    if (client) {
      const { topic, qos, payload} = context;

      client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          console.log("Publish error: ", error);
        } else {
          handleClick("Mensagem publicada!", "success");
        }
      });
    }
  };

  const mqttPublishCanal = (topic, qos, voz) => {
    if (client) {
      client.publish(topic, voz, { qos }, (error) => {
        if (error) {
          console.log("Publish error: ", error);
        } else {
          handleClick("Mensagem publicada!", "success");
        }
      });
    }
  };

  const mqttSub = (subscription) => {
    if (client) {
      const { topic, qos } = subscription;
      client.subscribe(topic, { qos }, (error) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        } else {
          handleClick("Subscrito", "success");
        }
        setIsSub(true);
      });
    }
  };

  const mqttUnSub = (subscription) => {
    if (client) {
      const { topic } = subscription;
      client.unsubscribe(topic, (error) => {
        if (error) {
          console.log("Unsubscribe error", error);
          return;
        }
        setIsSub(false);
      });
    }
  };

  return (
    <>
      <Connection
        connect={mqttConnect}
        disconnect={mqttDisconnect}
        connectBtn={connectStatus}
      />
     <br/>
      <div>
      <Card >
      <div className="list row">
        <ul className="list-group">
        <div className="center">
        <Row>
        <QosOption.Provider value={qosOption}>
          <SubscriberModal
            sub={mqttSub}
            unSub={mqttUnSub}
            showUnsub={isSubed}
          />
          <PublishModal publish={mqttPublish} />
        </QosOption.Provider>
        <SpeechModal  publish={mqttPublishCanal}/>
        <SynthesisModal payload={payload}/>
        <ReceptorModal payload={payload} />
</Row>
    </div>
          
        </ul>
      </div>
      </Card>
      <br />
    </div>
    </>
     
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={5}>
      <HookMqtt />
    </SnackbarProvider>
  );
}
