import {db} from "../../../firebase";

const db2 = db.collection("/addhoras");

const pegaTudo = () => {
  return db2;
};

const criaRegistro = (data) => {
  return db2.add(data);
};

const atualiza = (id, value) => {
  return db2.doc(id).update(value);
};

const remove = (id) => {
  return db2.doc(id).delete();
};

const DBService = {
  pegaTudo,
  criaRegistro,
  atualiza,
  remove,
};

export default DBService;