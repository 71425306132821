import React, { useContext, useState } from "react";
import { Card, Form, Input, Row, Col, Select, Button } from "antd";
import { Modal } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { QosOption } from "../index";
const { Meta } = Card;

export default function PublishModal({ publish }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [form] = Form.useForm();
  const qosOptions = useContext(QosOption);

  const record = {
    topic: "clientemqtt",
    qos: 0,
  };

  const onFinish = (values) => {
    publish(values);
  };

  const PublishForm = (
    <Form
      layout="vertical"
      name="basic"
      form={form}
      initialValues={record}
      onFinish={onFinish}
    >
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            label="Topic"
            name="topic"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="QoS"
            name="qos"
          >
            <Select options={qosOptions} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Payload"
            name="payload"
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col span={8} offset={16} style={{ textAlign: 'right' }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Publicar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )

  return (
    <>
       <Card
        hoverable
        style={{
          width: 170,
          marginleft: 'auto',
  marginright: 'auto',
        }}
        cover={
          <img
            alt="example"
            src="https://suporte.agteletronic.com.br/icons/Atis(18).png"
            onClick={handleShow}
            style={{
              width: 145,
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "5px",
              margin: "10px",
              backgroundColor: "#f5faf6",
            }}
          />
        }
      >
        <Meta
          title="Publicar"
          onClick={handleShow}
        />
      </Card>
      <br/>
      <Modal className="special_modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Publicar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>{PublishForm}</Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
