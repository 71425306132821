import React, { useEffect, useState } from "react";
import { Card, Button } from "antd";
import { Modal } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import useSpeechSynthesis from "./useSpeechSyntesis";
import { Container } from "./shared";
const { Meta } = Card;

export default function SynthesisModal({ payload }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("Eu sou um robô");
  const [pitch, setPitch] = useState(1);
  const [rate, setRate] = useState(1);
  const [voiceIndex, setVoiceIndex] = useState(16);
  const onEnd = () => {
    // You could do something here after speaking has finished
  };
  const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis({
    onEnd,
  });

  const voice = voices[voiceIndex] || null;

  const styleFlexRow = { display: "flex", flexDirection: "row" };
  const styleContainerRatePitch = {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
  };

  useEffect(() => {
    if (payload.topic) {
      setMessages((messages) => [...messages, payload]);
      speak({ text: payload.message, voice, rate, pitch });
    }
  }, [payload]);

  return (
    <>
      <Card
        hoverable
        style={{
          width: 170,
          marginleft: 'auto',
  marginright: 'auto',
        }}
        cover={
          <img
            alt="example"
            src="/icons/8683415_chat_box_message_cell_phone_icon.png"
            onClick={handleShow}
            style={{
              width: 145,
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "5px",
              margin: "10px",
              backgroundColor: "#f5faf6",
            }}
          />
        }
      >
        <Meta
          title="Sintetizador"
          onClick={handleShow}
        />
      </Card>
      <br/>
      <Modal
        className="special_modal"
        show={show}
        onHide={handleClose}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Síntese de fala
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <form>
              {!supported && (
                <p>
                  Ah, não, parece que seu navegador não suporta fala Síntese.
                </p>
              )}
              {supported && (
                <React.Fragment>
                  <p>
                    {`Digite uma mensagem abaixo e clique em 'Falar'
                e o SpeechSynthesis irá lê-lo em voz alta.`}
                  </p>
                  <label htmlFor="voice">Voz</label>
                  <select
                    id="voice"
                    name="voice"
                    value={voiceIndex || ""}
                    onChange={(event) => {
                      setVoiceIndex(event.target.value);
                    }}
                  >
                    <option value="">Default</option>
                    {voices.map((option, index) => (
                      <option key={option.voiceURI} value={index}>
                        {`${option.lang} - ${option.name}`}
                      </option>
                    ))}
                  </select>
                  <div style={styleContainerRatePitch}>
                    <div style={styleFlexRow}>
                      <label htmlFor="rate">Taxa: </label>
                      <div className="rate-value">{rate}</div>
                    </div>
                    <input
                      type="range"
                      min="0.5"
                      max="2"
                      defaultValue="1"
                      step="0.1"
                      id="rate"
                      onChange={(event) => {
                        setRate(event.target.value);
                      }}
                    />
                  </div>
                  <div style={styleContainerRatePitch}>
                    <div style={styleFlexRow}>
                      <label htmlFor="pitch">Tom: </label>
                      <div className="pitch-value">{pitch}</div>
                    </div>
                    <input
                      type="range"
                      min="0"
                      max="2"
                      defaultValue="1"
                      step="0.1"
                      id="pitch"
                      onChange={(event) => {
                        setPitch(event.target.value);
                      }}
                    />
                  </div>
                  <label htmlFor="message">Mensagem</label>
                  <textarea
                    id="message"
                    name="message"
                    rows={3}
                    value={text}
                    onChange={(event) => {
                      setText(event.target.value);
                    }}
                  />
                  {speaking ? (
                    <button type="button" onClick={cancel}>
                      Cancelar
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => speak({ text, voice, rate, pitch })}
                    >
                      Falar
                    </button>
                  )}
                </React.Fragment>
              )}
            </form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
