import firebase from 'firebase/compat/app';
import "firebase/compat/firestore";
import "firebase/compat/auth";

var firebaseConfig = {
  apiKey: "AIzaSyADtQMTC15pgD8VyyUs4LG3hlU8JhbcicQ",
  authDomain: "primeiroprojeto-16a39.firebaseapp.com",
  databaseURL: "https://primeiroprojeto-16a39.firebaseio.com",
  projectId: "primeiroprojeto-16a39",
  storageBucket: "primeiroprojeto-16a39.appspot.com",
  messagingSenderId: "361326080851",
  appId: "1:361326080851:web:b49eb21fb79b153b70a7a6",
  measurementId: "G-NDHKEJPHPD"
};


firebase.initializeApp(firebaseConfig);
// Initialize Firebase

export const db = firebase.firestore();

export const auth = firebase.auth();


