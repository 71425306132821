import React, { useContext, useState } from "react";
import { Card, Form, Input, Row, Col, Select, Button } from "antd";
import { Modal } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { QosOption } from "../index";
const { Meta } = Card;

export default function SubscriberModal({ sub, unSub, showUnsub }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [form] = Form.useForm();
  const qosOptions = useContext(QosOption);

  const record = {
    topic: "clientemqtt",
    qos: 0,
  };

  const onFinish = (values) => {
    sub(values);
  };

  const handleUnsub = () => {
    const values = form.getFieldsValue();
    unSub(values);
  };

  const SubForm = () => {
    return (
      <Card>
        <Form
          layout="vertical"
          name="basic"
          form={form}
          initialValues={record}
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="Topic" name="topic">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="QoS" name="qos">
                <Select options={qosOptions} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                Subscrever
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
              {
              showUnsub ?
                <Button type="danger" style={{ marginLeft: '10px' }} onClick={handleUnsub}>
                  Cancelar
                </Button>
                : null
            }
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  };

  return (
    <>
      <Card
        hoverable
        style={{
          width: 170,
          marginleft: 'auto',
  marginright: 'auto',
        }}
        cover={
          <img
            alt="example"
            src="https://suporte.agteletronic.com.br/icons/Atis(6).png"
            onClick={handleShow}
            style={{
              width: 145,
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "5px",
              margin: "10px",
              backgroundColor: "#f5faf6",
            }}
          />
        }
      >
        <Meta
          title="Subscrever"
          onClick={handleShow}
        />
      </Card>
      <br/>
      <Modal className="special_modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Subscrever
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SubForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
